.particles-bg-canvas-self {
  background: #673ab7; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #512da8, #673ab7); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to left,
    #512da8,
    #673ab7
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  position: fixed;
  z-index: -1;
}

.App {
  text-align: center;
}

.center {
  text-align: center;
}

.center-flex {
  display:flex;
  justify-content: center;
}

p {
  color:#fff;
}

.button {
  cursor: pointer;
}